@tailwind base;
@tailwind components;
@tailwind utilities;


.custom-quill {
  @apply rounded-lg border-2 border-secondary text-gray-800;
}

.custom-quill .ql-toolbar {
  @apply bg-white rounded-t-lg !important;
}

.custom-quill .ql-container {
  @apply bg-white rounded-b-lg;
  height: 500px;
}

.custom-quill .ql-container.ql-snow {
  border: none;
}

.custom-quill .ql-toolbar.ql-snow {
  @apply border-b-secondary border-2;
  border-top: none; 
  border-left: none;
  border-right: none;  
}


.loading-indicator:before {
    content: '';
    background: #00000060;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: 'Loading...';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 10010;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;        
}

.btm-nav {
  height: 6rem !important;
}

.table th:first-child{
  z-index: 0 !important;
}

.ck.ck-content {
   height: 200px !important;
   /* background: var(--ck-color-base-border) !important; */
   --tw-border-opacity: 1 !important;
   border-color: hsl(var(--p) / var(--tw-border-opacity)) !important;
   background-color: #2A303C  !important;
   color: #fff  !important;
 }
 .ck-toolbar{
  /* background: var(--ck-color-base-border) !important; */
    --tw-border-opacity: 1 !important;
    border-color: hsl(var(--p) / var(--tw-border-opacity)) !important;
    background-color: #2A303C !important;
 }
 .ck.ck-toolbar.ck-toolbar_grouping * {
   color: white !important;
 } .ck.ck-toolbar.ck-toolbar_grouping *:hover {
    background-color: #2A303C !important;
 }
 .col-second h1{
      font-size: revert !important;
 }

 @layer utilities {
    @variants responsive {
        .album {
            column-gap: 1.5em;
            column-count: 0;
        }
        .album-sm {
            column-gap: 1.5em;
            column-count: 1;
        }
        .album-md {
            column-gap: 1.5em;
            column-count: 2;
        }
        .break-inside {
            break-inside: avoid;
        }
    }
}


.audio-recorder {
  background-color: transparent !important;
  box-shadow: none !important;
  color: white;
  width: 70% !important;
  height: 46px;
}

.audio-recorder > .audio-recorder-timer {
  color: white !important;
}  
.audio-recorder > .audio-recorder-mic {
  display: none
}

.audio-recorder .audio-recorder-visualizer {
  flex-grow: unset;
} 

.audio-recorder .audio-recorder-options   {
  display: none
}

#messages audio {
  max-width: 100%;
}