.logo {
  min-width: 2.5rem;
  max-width: 6rem;
}

.rotate {
  animation: rotation 1s infinite linear;
  max-width: 102px;
  max-height: 102px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 767px) {
  main {
    padding-bottom: calc(90px + 2rem);
  } 
}

/* Youtube styles */
.video-responsive{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* Schedule additional styles */
@media screen and (max-width: 767px) {

  .fc-dayGridMonth-button, 
  .fc-dayGridWeek-button,
  .fc-dayGridDay-button {
    display: none;
  }
  
}

.fc .fc-daygrid-event {
  margin: 0.25rem !important;
  border-radius: var(--rounded-btn, 0.5rem);
  animation: button-pop var(--animation-btn, 0.25s) ease-out;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
}

.fc-h-event .fc-event-main {
  margin: 0.5rem !important;
  overflow: hidden;
}


.btn-nav {
  height: 6rem;
}

.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.home-plate-wrapper {
  position: relative;
  display: inline-flex;
  transform: rotateX(120deg);
}

#home-plate{
  height: 60px;
  width: 158px;
  background-color:rgba(255, 255, 255, 0.5);
}
#home-plate:before, #home-plate:after{
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
}
#home-plate:before{
  border-top-color: rgba(255, 255, 255, 0.5);
  border-width: 79px;
  left: 158px;
  margin-left: -8px;
  position: absolute;
  width: 50px;
  margin-top: -120px;
}

.batters-box{
  height: 180px;
  width: 120px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  margin-left: 15px;  
  margin-right: 15px;  
}

@media screen and (max-width: 645px) {
  .fc-duplicateWeek-button,
  .fc-dayGridMonth-button,
  .fc-dayGridWeek-button,
  .fc-dayGridDay-button {
    display: none !important;
  }
}

.event-outer {
  height: 24px;
}

.event-outer:hover .event-inner {
  width: calc(100% - 15px);
  overflow: hidden;
}

.event-outer .nav-btns {
  display: none;
}

.event-outer:hover .nav-btns {
  display: flex;
}

@media screen and (max-width: 643px) {
  .chart canvas {
    max-width: 500px;
  }
}

@media screen and (max-width: 567px) {
  .chart canvas {
    max-width: 350px;
  }
}

@media screen and (max-width: 545px) {
  .chart canvas {
    max-width: 320px;
  }
}

@media screen and (max-width: 767px) {

  .drawer-side .menu li:last-child {
    margin-bottom: 120px;
  }
}

.date-picker-custom {
    width: 100% !important;
}

.date-picker-custom .w-64 {
  width: auto;
}

.ellipsis-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}


@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.ellipsis {
  display: inline-block;
}

.ellipsis span {
  opacity: 0; /* Start hidden */
  animation: blink 1.4s infinite; /* Apply the blink animation */
}

.ellipsis span:nth-child(1) {
  animation-delay: 0s; /* No delay for the first dot */
}

.ellipsis span:nth-child(2) {
  animation-delay: 0.2s; /* Delay the second dot */
}

.ellipsis span:nth-child(3) {
  animation-delay: 0.4s; /* Further delay the third dot */
}